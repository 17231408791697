
import {
  computed, defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { Names } from '@/plugins/vue-router';
import { useTrackPlayer } from '@/domains/course/composables/useTrackPlayer';
import { ButtonsTypes, IButtonsState } from '@/domains/assignment';
import { IBreadcrumb } from '@/domains/common';
import { CourseTreeNodeType } from '@/domains/course';
import TrackSidebar from '@/components/tracks/TrackSidebar/TrackSidebar.vue';
import { AtomType } from '@/domains/atom';
import { useI18n } from '@/plugins/vue-i18n';
import { BasePage } from '@/components/ui/BasePage';
import StepsButtons from '@/domains/assignment/components/StepsButtons/StepsButtons.vue';

export default defineComponent({
  name: 'AssignmentTrackPlayerLayout',

  components: {
    BasePage,
    StepsButtons,
    TrackSidebar,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },

    // NOTE: Есть только при переходе по прямой ссылке
    stepId: {
      type: String,
      default: undefined,
    },
  },

  setup(props) {
    const { playerSessionId, stepId } = toRefs(props);
    const router = useRouter();
    const { t } = useI18n();

    const {
      isLoading,
      isPrevStepAvailable,
      isNextStepAvailable,
      currentStep,
      isLinearFlow,
      course,
      trackSession,
      assignment,
      courseCompletionRate,
      steps,
      assignmentFinishData,
      fetch,
      onStepNextHandler,
      onStepPrevHandler,
      onStepSetHandler,
    } = useTrackPlayer({
      trackSessionId: playerSessionId,
      initialStepId: stepId,
    });

    onMounted(async () => {
      await fetch();

      if (!trackSession.value || !assignment.value) {
        await router.replace({
          name: Names.R_ERROR_404,
        });
      }
    });

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('AssignmentTrackPlayerLayout.breadcrumbs.myLearning'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-learning',
      },
      {
        text: course.value?.name ?? '',
        to: {
          name: Names.R_APP_LEARNING_CATALOGUE,
        },
        'data-test-label': 'track-name',
      },
      {
        text: currentStep.value?.current.name ?? '',
        disabled: true,
        'data-test-label': 'step-name',
      },

    ]);

    const buttonState = computed<IButtonsState>(() => ({
      [ButtonsTypes.prev]: {
        enabled: isPrevStepAvailable.value,
        isLoading: false,
      },
      [ButtonsTypes.next]: {
        enabled: isNextStepAvailable.value,
        isLoading: isLoading.value,
        showTooltip: currentStep.value?.current.itemType === CourseTreeNodeType.STEP
          ? currentStep.value?.current?.atomType === AtomType.QUIZ && !isNextStepAvailable.value
          : false,
        tooltipText: t('StepsButtons.nextButtonTooltipTextQuiz'),
      },
      isShowNextStepLinearWarning: !isNextStepAvailable.value && isLinearFlow.value,
    }));

    return {
      isLoading,
      breadcrumbs,
      buttonState,
      currentStep,
      courseCompletionRate,
      steps,
      assignmentFinishData,
      onStepSetHandler,
      onStepNextHandler,
      onStepPrevHandler,
    };
  },
});
