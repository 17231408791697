import { AtomType } from '@/domains/atom';
import { PlayerSessionStatus } from '@/domains/common';
import Names from '@/plugins/vue-router/routeNames';

const dict: Record<AtomType, Record<PlayerSessionStatus, string>> = {
  [AtomType.MULTILEVELTEST]: {
    [PlayerSessionStatus.NEW]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_INTRO,
    [PlayerSessionStatus.DELETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_INTRO,
    [PlayerSessionStatus.CANCELED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_INTRO,
    [PlayerSessionStatus.ACTIVE]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_PLAYER,
    [PlayerSessionStatus.COMPLETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_RESULT,
    [PlayerSessionStatus.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
  },
  [AtomType.MULTICONTENT]: {
    [PlayerSessionStatus.NEW]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_PLAYER,
    [PlayerSessionStatus.DELETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_PLAYER,
    [PlayerSessionStatus.CANCELED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_PLAYER,
    [PlayerSessionStatus.COMPLETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_PLAYER,
    [PlayerSessionStatus.ACTIVE]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_PLAYER,
    [PlayerSessionStatus.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
  },
  [AtomType.QUIZ]: {
    [PlayerSessionStatus.NEW]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_INTRO,
    [PlayerSessionStatus.DELETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_INTRO,
    [PlayerSessionStatus.CANCELED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_INTRO,
    [PlayerSessionStatus.ACTIVE]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_PLAYER,
    [PlayerSessionStatus.COMPLETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_RESULT,
    [PlayerSessionStatus.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
  },
  [AtomType.SCORM]: {
    [PlayerSessionStatus.NEW]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_PLAYER,
    [PlayerSessionStatus.DELETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_PLAYER,
    [PlayerSessionStatus.CANCELED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_PLAYER,
    [PlayerSessionStatus.ACTIVE]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_PLAYER,
    [PlayerSessionStatus.COMPLETED]: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_PLAYER,
    [PlayerSessionStatus.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
  },
  [AtomType.TRACK]: {
    [PlayerSessionStatus.NEW]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.DELETED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.CANCELED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.ACTIVE]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.COMPLETED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
  },
  [AtomType.PROGRAM]: {
    [PlayerSessionStatus.NEW]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.DELETED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.CANCELED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.ACTIVE]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.COMPLETED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
  },
  [AtomType.UNKNOWN]: {
    [PlayerSessionStatus.NEW]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.DELETED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.CANCELED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.ACTIVE]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.COMPLETED]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
    [PlayerSessionStatus.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
  },
};

export const getRouteNameForAtomTypeAndStatus = (
  atomType: AtomType, stepStatus: PlayerSessionStatus,
) => dict[atomType][stepStatus];
